
import { useState, useEffect } from 'react'
import iconChimpa from './../../../assets/img/icon-chimpance.svg'
import parallax03 from './../../../assets/img/bg-parallax-03.svg'
import parallax04 from './../../../assets/img/bg-parallax-04.png'

export default function SectionEight(){

    // Definir la fecha inicial (1 de diciembre de 2014)
  const fechaInicial = new Date(2014, 11, 1); // Mes 11 es diciembre (en JavaScript los meses son indexados desde 0)
  
  const [anios, setAnios] = useState(0);

  // Calcular los años transcurridos cada vez que el componente se renderice
  useEffect(() => {
    const calcularAnios = () => {
      const fechaActual = new Date();
      const diferenciaTiempo = fechaActual - fechaInicial;
      const aniosTranscurridos = diferenciaTiempo / (1000 * 60 * 60 * 24 * 365.25); // Convertir milisegundos a años
      setAnios(Math.floor(aniosTranscurridos)); // Redondear hacia abajo al número entero más cercano
    };

    calcularAnios();
  }, []);

    return(
        <section id="contactanos" className="d-none d-md-flex position-relative">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 mb-5 mb-md-0">
                        <h2 className="fc-lightBlue mb-5">Hace más de {anios} años acompañamos la evolución digital de empresas
                        </h2>
                        <a href="#" className="btn btn-outline-primary-icon mt-4 d-inline-flex align-items-lg-center">
                            Contactanos
                            <img loading="lazy" src={iconChimpa} />
                        </a>
                    </div>
                </div>
            </div>
            <img loading="lazy" alt="background" src={parallax03} className="img-03 rellax"
                data-rellax-speed=".2" />
            <img loading="lazy" src={parallax04} className="img-04" />
        </section>
    )
}