import SectionOne from "./section_1/SectionOne";
import SectionTwo from "./section_2/SectionTwo";
import SectionThree from "./section_3/SectionThree";
import SectionFour from "./section_4_scroll_celulares/SectionFour";
import SectionFive from "./section_5/SectionFive";
import SectionSix from "./section_6/SectionSix";
import SectionSeven from "./section_7/SectionSeven";
import SectionEight from "./section_8/SectionEight";
import SectionNine from "./section_9/SectionNine";
import SectionTen from "./section_10/SectionTen";
import SectionEleven from "./section_11/SectionEleven";
import SectionTwelve from "./section_12/SectionTwelve";
import SectionThirteen from "./section_13/SectionThirteen";
import SectionFourteen from "./section_14/SectionFourteen";
import SectionFiveteen from "./section_15/SectionFiveteen";

import MobileNavbar from '../../layouts/MobileNavbar'
import Footer from '../../layouts/Footer'
import Navbar from '../../layouts/Navbar'
import React, {useEffect, useRef} from 'react'
import ContactComponent from "../shared-components/contactComponent/ContactComponent";
import TestimoniosComponent from "../shared-components/testimoniosComponent/Testimonios";

export default function Home({isMobile}){
    const [menuIsOpen, setMenuIsOpen] = React.useState(false);
    const [lottieHome, setLottieHome] = React.useState(true);
    const [lottieContact, setLottieContact] = React.useState(false);
    const [lottieJungle, setLottieJungle] = React.useState(false);

    const sectionLottieJunglaRef = useRef()
    const sectionThreeRef = useRef()
    const sectionContact = useRef()
      
    useEffect(() => {
        const handleScroll = (event) => {

            // Detect when is in section three, to pause Hero lottie
          if (sectionThreeRef.current) {
            if(sectionThreeRef.current.getBoundingClientRect().y < -40){
                if(lottieHome) setLottieHome(false)
            }else{
                if(!lottieHome) setLottieHome(true)
            }
          }
          // Detect to play/pause Contact lottie
          if (sectionContact.current){
            if(sectionContact.current.getBoundingClientRect().y < 1000){
                if(!lottieContact)setLottieContact(true)
            }
           
            if(sectionContact.current.getBoundingClientRect().y >= 1000){
                if(lottieContact)setLottieContact(false)
            }

          }

          if(sectionLottieJunglaRef.current){
            if(sectionLottieJunglaRef.current.getBoundingClientRect().y<1300){
                setLottieJungle(true)
            }else{
                setLottieJungle(false)
            }
          }


        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, [lottieHome, lottieContact]);


    return(
        <>
            <Navbar isMobile={isMobile} menuIsOpen={menuIsOpen} setMenuIsOpen={setMenuIsOpen} />
            <h1 className='position-absolute opacity-0'>Empresa de diseño y desarrollo web en Argentina</h1>
                {isMobile ?
                    <>

                        {/* Renderiza componentes para mobile */}
                        <MobileNavbar  menuIsOpen={menuIsOpen} setMenuIsOpen={setMenuIsOpen} />
                        <SectionOne lottieHome={lottieHome}/>
                        <div ref={sectionThreeRef}>
                            <SectionThree />
                        </div>
                        <SectionFive />
                        <SectionSix />
                        <SectionSeven />
                        <SectionTen />
                        <SectionEleven />
                        <SectionFourteen />
                        <TestimoniosComponent />
                        <div ref={sectionContact}>

                        <ContactComponent lottieContact={lottieContact} isMobile={isMobile} />
                        </div>
                    </>
                        :   
                    <>
                        {/* Renderiza componentes para desktop */}
                        <SectionOne lottieHome={lottieHome}/>
                        <SectionTwo />
                        <div  ref={sectionThreeRef}>                    
                            <SectionThree/>
                        </div>
                        <SectionFour contactDiv = {sectionContact}/>
                        <SectionEight />
                        <SectionNine />
                        <SectionTwelve />
                        <div ref={sectionLottieJunglaRef}>
                            <SectionThirteen lottieJungle={lottieJungle}/>
                        </div>
                        <SectionFiveteen />
                        <TestimoniosComponent />
                        <div ref={sectionContact}>
                            <ContactComponent lottieContact={lottieContact}   isMobile={isMobile} />
                        </div>
                    </>
                }

            <Footer />
        </>
    )
}