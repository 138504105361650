import React, { useEffect, useRef, useState } from "react";
import "./section_four_styles.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default function SectionFour({ contactDiv }) {
  const phone1Ref = useRef(null);
  const phone2Ref = useRef(null);
  const phone3Ref = useRef(null);
  const classOneRef = useRef(null);
  const classTwoRef = useRef(null);
  const classThreeRef = useRef(null);
  const menuPropiedades = useRef(null);

  const travelToContact = () => {
    console.log(contactDiv.current.getBoundingClientRect().y);
    console.log(menuPropiedades.current.getBoundingClientRect().y);
    window.scrollBy({
      top: contactDiv.current.getBoundingClientRect().y - 1300,
      behavior: "smooth",
    });
    // contactDiv.current.scrollIntoView({ behavior: 'smooth' });
  };

  const interpolateClipPath = (progress) => {
    const y1 = 100;
    const y2 = 0;

    const y3 = y1 + (y2 - y1) * progress;

    return `polygon(0 100%, 100% 100%, 100% ${y3}%, 0% ${y3}%)`;
  };

  useEffect(() => {
    // Configuración de la animación con ScrollTrigger
    const tl = gsap.timeline({
        scrollTrigger: {
          trigger: ".animated-element", // Elemento que va a activar la animación
          start: "top bottom", // Cuando el top del elemento llega al bottom de la ventana
          end: "bottom bottom", // Cuando el bottom del elemento llega al bottom de la ventana
          scrub: true, // Sincroniza la animación con el desplazamiento
          onUpdate: (self) => {
            const progress = self.progress;
  
            if (progress < 0.33) {
             
            } else if (progress < 0.66) {
              // Etapa 2: Mostrar la segunda imagen
              const clipPath2 =interpolateClipPath((progress - 0.33) / 0.33); // Interpolamos en el siguiente 33%
              gsap.to(".image2", { clipPath: clipPath2,  duration: 0.1 });
            } else {
              // Etapa 3: Mostrar la tercera imagen
              const clipPath3 = interpolateClipPath((progress - 0.66) / 0.33);  // Interpolamos en el último 34%
              gsap.to(".image3", { clipPath: clipPath3,  duration: 0.1 });
            }
          },
        },
      });

 

    return () => {
      // Limpiar los ScrollTriggers cuando el componente se desmonte
      tl.scrollTrigger.kill();
    };
  }, []);

  return (
    <section
      id="menu_propiedades"
      className="d-none d-md-block position-relative"
      ref={menuPropiedades}
    >
      <div className="container">
        <div className="article position-relative">
          <div className="row animated-element">
            <div className="col-6">
              <div className="h-screen">
                <div className="h-1001">
                  <h1
                    data-aos="fade-right"
                    className="titles-sliders fc-lightBlue mb-3"
                  >
                    WEB <span className="fb-lightBlue">UX UI</span>
                  </h1>
                  <div
                    data-aos="fade-right"
                    data-aos-delay="300"
                    className="d-flex mb-5"
                  >
                    <img
                      loading="lazy"
                      src="/images/img/wp.svg"
                      className="me-4"
                      alt="WordPress"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Wordpress"
                    />
                    <img
                      loading="lazy"
                      src="/images/img/bs.svg"
                      className="me-4"
                      alt="Bootstrap"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Bootstrap"
                    />
                    <img
                      loading="lazy"
                      src="/images/img/html.svg"
                      className="me-4"
                      alt="HTML"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="HTML"
                    />
                    <img
                      loading="lazy"
                      src="/images/img/js.svg"
                      className="me-4"
                      alt="JavaScript"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="JavaScript"
                    />
                    <img
                      loading="lazy"
                      src="/images/img/laravel.svg"
                      className="me-4"
                      alt="Laravel"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Laravel"
                    />
                    <img
                      loading="lazy"
                      src="/images/img/angular.svg"
                      className="me-4"
                      alt="Angular"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Angular"
                    />
                    <img
                      loading="lazy"
                      src="/images/img/react.svg"
                      className="me-4"
                      alt="React"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="React"
                    />
                  </div>
                  <h5 data-aos="fade-right" data-aos-delay="400">
                    <strong>La hacemos más fácil.</strong> Creamos soluciones
                    nuevas y <br />
                    experiencias digitales que enamoran y convierten clientes.{" "}
                  </h5>
                  <div className="mb-4 mt-4">
                    <span data-aos="zoom-in" className="tag-outline me-2">
                      Sitio Institucional
                    </span>
                    <span
                      data-aos="zoom-in"
                      data-aos-delay="200"
                      data-aos-duration="800"
                      className="tag-outline me-2"
                    >
                      E-commerce
                    </span>
                    <span
                      data-aos="zoom-in"
                      data-aos-delay="400"
                      data-aos-duration="800"
                      className="tag-outline me-2"
                    >
                      Plataforma Web
                    </span>
                    <span
                      data-aos="zoom-in"
                      data-aos-delay="600"
                      data-aos-duration="800"
                      className="tag-outline me-2"
                    >
                      Apps
                    </span>
                    <span
                      data-aos="zoom-in"
                      data-aos-delay="800"
                      data-aos-duration="800"
                      className="tag-outline me-2"
                    >
                      Landing Page
                    </span>
                  </div>
                  <div className="mt-4">
                    <a
                      href="/desarrollo-web"
                      data-aos="fade-right"
                      data-aos-delay="700"
                      className="btn btn-outline-primary"
                    >
                      ¡Lo necesito!
                    </a>
                  </div>
                </div>
              </div>
              <div className="h-screen">
                <div className="h-1001">
                  <h1
                    data-aos="fade-right"
                    data-aos-delay="100"
                    className="titles-sliders fc-lightBlue  mb-4 lh-1"
                  >
                    BRANDING <br /> <span className="fb-lightBlue">MARCAS</span>
                  </h1>
                  <h5 data-aos="fade-right" data-aos-delay="300">
                    <strong>Que la actualidad no te coma.</strong>
                    Actualiza, renueva o <br />
                    construye tu marca para la audiencia de hoy, sin dejar{" "}
                    <br />
                    atrás lo que la hace única.
                  </h5>
                  <div className="mb-4 mt-4">
                    <span
                      data-aos="zoom-in"
                      data-aos-delay="200"
                      data-aos-duration="500"
                      className="tag-outline me-2"
                    >
                      Naming
                    </span>
                    <span
                      data-aos="zoom-in"
                      data-aos-delay="400"
                      data-aos-duration="500"
                      className="tag-outline me-2"
                    >
                      Papelería
                    </span>
                    <span
                      data-aos="zoom-in"
                      data-aos-delay="600"
                      data-aos-duration="500"
                      className="tag-outline"
                    >
                      Manual de marca
                    </span>
                  </div>
                  <div className="mt-4">
                    <a
                      href="/branding"
                      data-aos="fade-right"
                      data-aos-delay="700"
                      className="btn btn-outline-primary"
                    >
                      ¡Lo necesito!
                    </a>
                  </div>
                </div>
              </div>
              <div className="h-screen">
                <div className="h-1001">
                  <h1
                    data-aos="fade-right"
                    data-aos-delay="100"
                    className="titles-sliders fc-lightBlue lh-1 mb-3"
                  >
                    CRO <br />
                    <span className="fb-lightBlue">LEADS VENTAS</span>
                  </h1>
                  <div
                    data-aos="fade-right"
                    data-aos-delay="300"
                    className="d-flex mb-5"
                  >
                    <img
                      loading="lazy"
                      src="/images/img/face.svg"
                      className="me-4"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Facebook Ads"
                    />
                    <img
                      loading="lazy"
                      src="/images/img/wp2.svg"
                      className="me-4"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="WhatsApp"
                    />
                    <img
                      loading="lazy"
                      src="/images/img/instagram.svg"
                      className="me-4"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Instagram"
                    />
                    <img
                      loading="lazy"
                      src="/images/img/ads.svg"
                      className="me-4"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Google Ads"
                    />
                    <img
                      loading="lazy"
                      src="/images/img/in.svg"
                      className="me-4"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Linkedin"
                    />
                    <img
                      loading="lazy"
                      src="/images/img/analytics.svg"
                      className="me-4"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Analytics"
                    />
                  </div>
                  <h5 data-aos="fade-right" data-aos-delay="400">
                    Hacemos campañas de MKT que se mueven al{" "}
                    <strong>ritmo digital</strong> para que <br />
                    no dejes de aumentar ventas y atraer nuevos clientes.
                  </h5>
                  <div className="mb-4 mt-4">
                    <span
                      className="tag-outline me-2"
                      data-aos="zoom-in"
                      data-aos-delay="200"
                    >
                      Facebook Ads
                    </span>
                    <span
                      className="tag-outline me-2"
                      data-aos="zoom-in"
                      data-aos-delay="400"
                    >
                      Google Ads
                    </span>
                    <span
                      className="tag-outline me-2"
                      data-aos="zoom-in"
                      data-aos-delay="600"
                    >
                      WhatsApp
                    </span>
                    <span
                      className="tag-outline me-2"
                      data-aos="zoom-in"
                      data-aos-delay="800"
                    >
                      CRM
                    </span>
                    <span
                      className="tag-outline me-2"
                      data-aos="zoom-in"
                      data-aos-delay="1000"
                    >
                      Analytics
                    </span>
                  </div>
                  <div className="mt-4">
                    <a
                      href="#coheteForm"
                      className="btn btn-outline-primary"
                      data-aos="fade-right"
                      data-aos-delay="700"
                    >
                      ¡Lo necesito!
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6  position-relative ">
              <div className="h-screen position-sticky  top-0 w-100">
                <div style={{zIndex:1}} className="position-absolute image1 w-100 h-100 d-flex justify-content-center align-items-center">
                  <img
                    loading="lazy"
                    src="/images/img/img-mockup-phone-01.webp"
                    className=""
                    ref={phone2Ref}
                    style={{ height: "70%" }}
                  />
                </div>
                <div style={{zIndex:2, clipPath: "polygon(0 100%, 100% 100%, 100% 100%, 0% 100%)"}} className="position-absolute image2 w-100 h-100 d-flex justify-content-center align-items-center">
                  <img
                    loading="lazy"
                    src="/images/img/img-mockup-phone-03.webp"
                    className=""
                    ref={phone2Ref}
                    style={{ height: "70%" }}
                  />
                </div>
                <div style={{zIndex:3, clipPath: "polygon(0 100%, 100% 100%, 100% 100%, 0% 100%)"}} className="position-absolute image3 w-100 h-100 d-flex justify-content-center align-items-center">
                  <img
                    loading="lazy"
                    src="/images/img/img-mockup-phone-02.webp"
                    className=""
                    ref={phone2Ref}
                    style={{ height: "70%" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
   
    </section>
  );
}