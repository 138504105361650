import React from 'react';
import './navbarBlog.css'

const NavbarBlog = ({ categories, categoryChange, keywordChange, searchSubmit, collapse, loading }) => {
  const categoryList = [, "UX", "UI", "Programación", "Marketing digital", "Branding"];
  const searchGlassSvg = <svg className="form__contenedorDeIcono__icono" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="32" d="M221.09 64a157.09 157.09 0 1 0 157.09 157.09A157.1 157.1 0 0 0 221.09 64Z" /><path fill="none" stroke="currentColor" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="32" d="M338.29 338.29L448 448" /></svg>
  const spiralSvg = <svg className="form__contenedorDeIcono__icono" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" strokeDasharray="15" strokeDashoffset="15" strokeLinecap="round" strokeWidth="2" d="M12 3C16.9706 3 21 7.02944 21 12"><animate fill="freeze" attributeName="stroke-dashoffset" dur="0.3s" values="15;0" /><animateTransform attributeName="transform" dur="1.5s" repeatCount="indefinite" type="rotate" values="0 12 12;360 12 12" /></path></svg>
  const crossSVG = <svg className="categorias__categoria__seleccionado__Icono" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16"><path fill="currentColor" fillRule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" clipRule="evenodd" /><path fill="currentColor" d="M11.854 4.854a.5.5 0 0 0-.707-.707L8 7.293L4.854 4.147a.5.5 0 1 0-.707.707L7.293 8l-3.146 3.146a.5.5 0 0 0 .707.708L8 8.707l3.147 3.147a.5.5 0 0 0 .707-.708L8.708 8z" /></svg>
  // Barra de busqueda
  return (
    <nav className={collapse ? "navbarBlog--collapsed" : "navbarBlog"}>
      {/* Formulario de palabra clave */}
      <div className="fondo__contenedor0"></div>
      <form onSubmit={searchSubmit} className={collapse ? "navbarBlog__form--collapsed" : "navbarBlog__form"}>
        {/* titulo */}
        <h1 className={collapse ? "form__titulo--collapsed" : "form__titulo"}>Mirá nuestro blog</h1>

        {/* Entrada de texto */}
        <div className="form__contenedor">
          <div className="form__contenedorDeIcono">
            {loading ? spiralSvg : searchGlassSvg}
          </div>
          <input
            type="text"
            onChange={keywordChange}
            className="form__contenedor__entradaDeTexto" />

          <button className={collapse ? "form__contenedor__boton--collapsed" : "form__contenedor__boton"} >Buscar</button>
        </div>
      </form>

      {/* Fondo */}
      <div className="navbarBlog__fondo">
        <div className="fondo__contenedor1">
          <img src="images/selva1.svg" alt="jungla de fondo" className="fondo__selva1" />
        </div>
        <div className="fondo__contenedor2">
          <img src="images/selva2.svg" alt="jungla de fondo" className="fondo__selva2" />
        </div>
      </div>


      {/* Selector de categorias */}
      <div className={collapse ? "navbarBlog__contenedor--collapsed" : "navbarBlog__contenedor"}>

        <ul className="navbarBlog__categorias">

          <li
            className={categories[0] ? "categorias__categoria2" : "categorias__categoria__seleccionado2"}
            onClick={(e) => categoryChange("")}
          >
            {"Todos"}
          </li>
          {categoryList.map((category, index) => (

            <li
              key={index}
              className={categories.includes(category) ? "categorias__categoria__seleccionado" : "categorias__categoria"}
              onClick={() => categoryChange(category)}
            >
              {category}{crossSVG}
            </li>

          ))}
        </ul>
      </div>

    </nav>
  );
};

export default NavbarBlog;
