import React from "react";
import { Fragment } from "react";
import "./apendix.css";

const Apendix = ({ headers }) => {
  const poseIntoPieze = (id) => {
    const elemento = document.getElementById(id);
    if (elemento) {
      const offsetTop = elemento.offsetTop - 105;
      window.scrollTo({
        top: offsetTop,
        behavior: "smooth",
      });
    }
  };
  console.log(headers);

  return (
    <div className="article_indice">
      <div className="article_indice_contenedor">
        <span className="article_indice_contenedor_titulo">
          <svg
            className="article_indice_contenedor_icono"
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 24 24"
          >
            <path
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.5}
              d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
            ></path>
          </svg>
          Seguí el hilo
        </span>
        <ul className="article_indice_contenedor_lista">
          {headers.map((heading, index) => (
            <Fragment key={index}>
              {heading.level <= 3 && (
                <li
                  className="article_indice_contenedor_lista_item pt-2"
                  onClick={() =>
                    poseIntoPieze(heading.text.replace(/\s+/g, "_"))
                  }
                >
                  {heading.text}
                </li>
              )}
            </Fragment>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Apendix;
