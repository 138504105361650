//función fetchArticles con validación de fechas
import { parseISO, format } from 'date-fns';
import { es } from 'date-fns/locale';

//Funcion para formatear fecha y darle forma
let dateFormater = (date) => {
  let commentDate = "Fecha no disponible";
  let parsedDate = parseISO(date)
  commentDate = format(parsedDate, 'd MMM. yyyy', { locale: es });
  return commentDate
}

//Funcion para obtener los artículos solicitados
export const fetchArticles = async (keyword = '', categories = [], page = 1, pageSize = 6) => {

  //Obtengo la url desde una variable de entorno.
  let baseURL = `${process.env.REACT_APP_STRAPI_URL}/api/articles?`;

  //Si es usuario escribio una keyword, la agrego a la proxima busqueda.
  if (keyword) {
    baseURL += `filters[$or][0][title][$contains]=${keyword}&`;
    baseURL += `filters[$or][1][lead][$contains]=${keyword}&`;
  }

  //Segun las categorias seleccionadas hago la busqueda.
  categories.forEach((category, index) => {
    baseURL += `filters[$or][${index}][category][$eq]=${category}&`;
  });

  //Armo el endpoint de Strapi para hacer una solicitud.
  baseURL += 'sort=publicationDate:desc&';
  baseURL += 'fields[0]=title&fields[1]=publicationDate&fields[2]=lead&fields[3]=category&fields[4]=slug&';
  baseURL += 'populate[mainPhoto][fields][0]=formats&';
  baseURL += 'populate[mainPhoto][fields][1]=id&';
  baseURL += 'populate[mainPhoto][populate]=formats.thumbnail&';
  baseURL += `pagination[page]=${page}&pagination[pageSize]=${pageSize}`;

  try {
    //Hago la peticion 
    const res = await fetch(baseURL);

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }

    const data = await res.json();

    const pageButtons = [];
    for (let index = 1; index <= data.meta.pagination.pageCount; index++) {
      const button = {
        page: index,
        active: index === data.meta.pagination.page,
        total: data.meta.pagination
      };
      pageButtons.push(button);
    }

    const reducedData = {
      data: data.data.map(article => {
        let formattedDate = "Fecha no disponible";
        if (article.attributes.publicationDate) {
          const parsedDate = parseISO(article.attributes.publicationDate);
          formattedDate = format(parsedDate, 'd MMM. yyyy', { locale: es });
        }

        return {
          id: article.id,
          title: article.attributes.title,
          category: article.attributes.category,
          publicationDate: formattedDate,
          lead: article.attributes.lead,
          slug: article.attributes.slug,
          mainPhoto:`${process.env.REACT_APP_STRAPI_URL}${article.attributes.mainPhoto.data.attributes.formats.large.url}`};
      }),
      meta: {
        pagination: pageButtons
      }
    };
    return { data: reducedData, meta: pageButtons };

  } catch (error) {
    console.error('Fetch articles failed:', error);
    return { data: [], pageButtons: [], error: error.message };
  }


};

//Funcion para obtener el artículo exaltado
export const fetchHilightArticle = async () => {

  let baseURL = `${process.env.REACT_APP_STRAPI_URL}/api/articles?`;

  baseURL += 'filters[highlight][$eq]=true&';
  baseURL += 'fields[0]=title&fields[1]=publicationDate&fields[2]=lead&fields[3]=category&fields[4]=slug&fields[5]=highlight&';
  baseURL += 'populate[mainPhoto][fields][0]=formats&';
  baseURL += 'populate[mainPhoto][fields][1]=id&';
  baseURL += 'populate[mainPhoto][populate]=formats.thumbnail&';
  baseURL += 'pagination[page]=1&pagination[pageSize]=1';

  try {
    const res = await fetch(baseURL);

    if (!res.ok) {
      console.error(`HTTP error! status: ${res.status}`);
    }

    const data = await res.json();

    if (data.data.length === 0) {
      return { data: null, error: 'No hay articulos destacados' };
    }

    const article = data.data[0];
    let formattedDate = "Fecha no disponible";
    if (article.attributes.publicationDate) {
      const parsedDate = parseISO(article.attributes.publicationDate);
      formattedDate = format(parsedDate, 'd MMM. yyyy', { locale: es });
    }

    const reducedArticle = {
      id: article.id,
      title: article.attributes.title,
      category: article.attributes.category,
      publicationDate: formattedDate,
      lead: article.attributes.lead,
      slug: article.attributes.slug,
      mainPhoto:`${process.env.REACT_APP_STRAPI_URL}${article.attributes.mainPhoto.data.attributes.formats.thumbnail.url}` 
    };
    return { data: reducedArticle };

  } catch (error) {
    console.error('Fetch highlighted article failed:', error);
    return { data: null, error: error.message };
  }
};

//Funcion para obtener el artículo a travez de su Slug
export const fetchArticleBySlug = async (slug) => {


  if (!slug) {
    console.error('Article slug is undefined');
    return { data: null, error: 'Article slug is undefined' };
  }

  //Funcion para obtener los encabezados del articulo
  const extractHeadings = (article) => {
    const headings = [];
  
    const processContent = (content) => {
      content.forEach((item) => {
        if (item.type === 'heading' && item.level >= 1 && item.level <= 6) {
          const headingText = item.children.map(child => child.text).join('');
          headings.push({
            level: item.level,
            text: headingText,
          });
        }
      });
    };
  
    if (article.content) {
      article.content.forEach(section => {
        if (section.__component === 'content.content' && section.content) {
          processContent(section.content);
        }
      });
    }
  
    if (article.conclusion) {
      processContent(article.conclusion);
    }
  
    return headings;
  };


  let baseURL = `${process.env.REACT_APP_STRAPI_URL}/api/articles?`
  baseURL += `filters[slug][$eq]=${slug}&`
  baseURL += `populate[mainPhoto]=*&`
  baseURL += `populate[content][populate]=*&`
  baseURL += `populate[lastContent][populate]=*&`
  baseURL += `populate[comments]=*&`

  try {
    console.log(baseURL)
    //Fetch del articulo.
    const res = await fetch(baseURL);
    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }
    const data = await res.json();
    console.log("data:", data)
    if (data.data.length === 0) {
      return { data: null, error: 'Article not found' };
    }
    const articleData = data.data[0];

    //Obtencion de headers del articulo.
    const indexHeaders = extractHeadings(articleData.attributes)

    // Formateo la fecha de publicación.
    let formattedPublicationDate = "Fecha no disponible";
    if (articleData.attributes.publicationDate) {
      const parsedDate = parseISO(articleData.attributes.publicationDate);
      formattedPublicationDate = format(parsedDate, 'd MMM. yyyy', { locale: es });
    }

    
    // Mapeo de la data para la créacion de un objeto con la información del artículo y los headers para el indice
    if(articleData){
      const article = {
        id: articleData.id,
        indexHeaders,
        title: articleData.attributes.title,
        slug: articleData.attributes.slug,
        category: articleData.attributes.category,
        conclusion: articleData.attributes.conclusion,
        publicationDate: formattedPublicationDate,
        introduction: articleData.attributes.introduction,
        mainPhoto: `${process.env.REACT_APP_STRAPI_URL}${articleData.attributes.mainPhoto.data.attributes.url}`,
        content: articleData.attributes.content.map(content => {
          if (content.__component === 'content.multimedia') {
            return {
              id: content.id,
              __component: content.__component,
              alternativeText: content.alternativeText,
              type: content.type,
              file: content.file ? {
                id: content.file.data.id,
                url: `${process.env.REACT_APP_STRAPI_URL}${content.file.data.attributes.url}`,
                name: content.file.data.attributes.name,
                previewUrl: content.file.data.attributes.previewUrl 
                  ? `${process.env.REACT_APP_STRAPI_URL}${content.file.data.attributes.previewUrl.replace('.gif', '.png')}`
                  : undefined,
              } : [],
            };
          }
  
          return {
            id: content.id,
            __component: content.__component,
            ...content,
          };
        }),
        lastContent: articleData.attributes.lastContent.map(content => {
          if (content.__component === 'content.multimedia') {
            return {
              id: content.id,
              __component: content.__component,
              alternativeText: content.alternativeText,
              type: content.type,
              file: content.file ? {
                id: content.file.data.id,
                url: `${process.env.REACT_APP_STRAPI_URL}${content.file.data.attributes.url}`,
                name: content.file.data.attributes.name,
              } : [],
            };
          }
  
          return {
            id: content.id,
            __component: content.__component,
            ...content,
          };
        }),
      };
      return { data: article };

    }
  } catch (error) {
    console.log(baseURL)
    console.error('Fetch article by slug failed:', error);
    return { data: null, error: error.message };
  }
};

//obtencion de comentarios.
export const fetchComments = async (Id) => {
  
  if (!Id) {
    console.error('Article id is undefined');
    return { data: null, error: 'Article id is undefined' };
  }
  let baseURL = `${process.env.REACT_APP_STRAPI_URL}/api/comment2s?`
  baseURL += `filters[articleId][$eq]=${Id}&`
  try {

    const res = await fetch(baseURL);

    const data = await res.json();

    const comments = data.data;

    return {
      comments: comments.map(comment =>
      ({
        id: comment.id,
        autor: comment.attributes.autor,
        content: comment.attributes.content,
        date: dateFormater(comment.attributes.createdAt),
      })
      ),
    }
  } catch (error) {
    console.error('Fetch comments failed:', error);
    return { data: null, error: error.message };
  }
}

//Post de comentario
export const submitComment = async (comment, articleId) => {


  if (!comment) {
    console.error('Comment is undefined');
    return { data: null, error: 'Article slug is undefined' };
  }
  if (!articleId) {
    console.error('Article id is undefined');
    return { data: null, error: 'Article slug is undefined' };
  }
  let baseURL = `${process.env.REACT_APP_STRAPI_URL}/api/comment2s`

  try {
    await fetch(baseURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        data: {
          content: comment.content,
          autor: comment.autor,
          autorEmail: comment.autorEmail,
          articleId: articleId
        }
      })
    });
  } catch (error) {
    console.error('Error submitting comment:', error);
  }
}